import React, { useEffect, useRef, useState } from "react";
import simpl from "../../assets/simpl.png";
import ola from "../../assets/ola.svg";
import zestMoney from "../../assets/zestmoney.svg";
import flexiPay from "../../assets/flexipay.svg";
import kotak from "../../assets/kotak.svg";
import lazyPay from "../../assets/lazypay.svg";
import { load } from "@cashfreepayments/cashfree-js";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import pay_later from "../../assets/payLater.svg";
import { toast } from "react-toastify";
import Select from "react-select";

const PayLater = ({
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  finalAmountWithMDR,
  isBlank,
}) => {
  const [cashfree, setCashfree] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
    setPaymentStatus("");
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    const loadCashfree = async () => {
      const cf = await load({ mode: process.env.REACT_APP_CASHFREE_MODE }); // Change to 'production' for live
      setCashfree(cf);
    };
    loadCashfree();
  }, []);

  useEffect(() => {
    payRef.current.disabled = true;
    if (isValidPhoneNumber) payRef.current.disabled = false;
  }, [phoneNumber]);

  useEffect(() => {
    containerRef.current.scrollTo({ left: 0, behavior: "smooth" });

    const handleMouseWheel = (event) => {
      const container = document.getElementById("pay-later-container");
      if (container) {
        container.scrollLeft += event.deltaY;
      }
    };
    document
      .getElementById("pay-later-container")
      ?.addEventListener("wheel", handleMouseWheel);
    return () => {
      document
        .getElementById("pay-later-container")
        ?.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  let payLaterServices = [
    "Simpl",
    "LazyPay",
    "FlexiPay",
    "Kotak",
    "ZestMoney",
    "OlaPostPaid",
  ];

  const options = payLaterServices.map((service) => ({
    value: service,
    label: service,
  }));

  const [services, setServices] = useState(payLaterServices);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);
  const containerRef = useRef(null);

  const handleServiceChange = (selectedServiceValue) => {
    const updatedServices = [
      selectedServiceValue,
      ...services.filter((service) => service !== selectedServiceValue),
    ];

    finalAmountWithMDR("paylater", selectedServiceValue);
    setServices(updatedServices);
    setSelectedService(selectedServiceValue);
    setShowPhoneNumberInput(
      selectedServiceValue !== "Search your PayLater Service"
    );
    containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
  };

  const handleImageClick = (service) => {
    handleServiceChange(service);
  };
  const handleSubmit = () => {
    // e.preventDefault();
    if (isValidPhoneNumber) {
      const provider = selectedService.toLocaleLowerCase();
      const p = cashfree.create("paylater", {
        values: {
          provider,
          phone: phoneNumber,
          buttonIcon: false,
        },
        classes: {
          complete: "empty_div",
        },
      });
      p.on("loaderror", (data) => {
        console.error("Load error:", data.error, data.value);
        setPaymentStatus("Error loading Pay Later component");
        toast.error(data.error.message);
      });
      p.on("ready", (d) => {
        cashfree
          .pay({
            paymentMethod: p,
            paymentSessionId: paymentId,
            redirectTarget: isBlank === "true" ? "_blank" : "_self",
            returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,

            //redirect: "if_required"
          })
          .then(function (data) {
            setPaymentStatus("Payment processing");
            if (data.error) {
              toast.error(data.error.message);
            }
          });
      });
      p.on("success", (response) => {
        setPaymentStatus("Payment successful!");
      });
      p.on("error", (error) => {
        setPaymentStatus("Payment failed:" + error.message);
      });

      p.mount("#pay-later-mount-point");
    } else {
      setPaymentStatus("Invalid phone number");
    }
  };

  childComponentFunctionRef.current = handleSubmit;
  return (
    <div className={`${menuIsOpen ? "h-[400px]" : "h-auto"}  `}>
      <div className="0">
        <div className="flex p-2 mt-4">
          <div className="flex justify-center items-center w-24 h-auto rounded-lg ml-4 mr-4">
            <div className="flex items-center w-auto">
              <img src={pay_later} className="w-14 bg-white" alt="pay" />
              <h6 className="text-xs text-[#717171] font-medium ml-2">
                Pay Later
              </h6>
            </div>
          </div>
          <div className="flex flex-col justify-center items-start mt-1 ">
            <p className="text-base text-black font-semibold">Pay Later</p>
            <p className="text-[12px] text-[#717171]">
              Choose Pay Later Vendor
            </p>
          </div>
        </div>
        <div className="p-4 h-auto max-h-96">
          <div className="flex flex-col w-full ">
            <Select
              className=" font-normal p-2 rounded-lg"
              id="serviceDropdown"
              value={selectedService}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              onChange={(selectedOption) =>
                handleServiceChange(selectedOption.value)
              }
              options={options}
              isSearchable
              placeholder={
                selectedService
                  ? selectedService
                  : "Search your PayLater Service"
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#E8EBF6",
                  border: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  "::placeholder": {
                    backgroundColor: "#YourDesiredColor",
                    opacity: 1,
                  },
                }),
              }}
            />
          </div>
        </div>

        <div
          className="flex cursor-pointer items-center h-[80px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7"
          id="pay-later-container"
          ref={containerRef}
        >
          {services.map(
            (service, index) =>
              service !== "Search your PayLater Service" && (
                <div
                  key={index}
                  className={`w-[140px] flex-none flex items-center justify-center h-[50px] bg-white shadow-black-shadow rounded-lg pay-later-image ${
                    service === selectedService ? "selected" : ""
                  }`}
                  onClick={() => handleImageClick(service)}
                >
                  <img
                    className="w-2/3"
                    src={getImageByService(service)}
                    alt={service}
                  />
                </div>
              )
          )}
        </div>
      </div>
      {showPhoneNumberInput && (
        <div className="flex justify-center mt-4 w-full">
          <form
            onSubmit={handleSubmit}
            className="w-11/12 flex flex-col items-center justify-center"
          >
            <label className="flex flex-col text-xs">
              <div className="flex items-center bg-white shadow-black-shadow rounded-lg py px-2 px-1 ml-5 mr-5 w-auto">
                <div className="text-basic mr-2 ml-1">+91</div>
                <input
                  className="border-b-2 ml-2 my-2 text-sm focus:outline-none"
                  placeholder="Phone Number"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                    setPhoneNumber(inputValue.slice(0, 10)); // Limit to 10 characters
                  }}
                  maxLength={10}
                  pattern="[0-9]*" // Allow only digits
                  title="Please enter only digits"
                  required
                />
                {isValidPhoneNumber && (
                  <img src={tick} className="m-1" alt="tick" />
                )}
                {!isValidPhoneNumber && (
                  <img src={fadedTick} className="m-1" alt="faded" />
                )}
              </div>
            </label>
            {/* <button id="paylater" className="w-11/12 bg-[#4a2bf8b6] h-10 border mt-1 rounded-lg" type="submit">
                            Submit
                        </button> */}
          </form>
        </div>
      )}
      <div id="pay-later-mount-point" className={"justify-center mt-8"}>
        <div></div>
      </div>

      {/* {paymentStatus && <div className="payment-status">{paymentStatus}</div>} */}
    </div>
  );
};
export default PayLater;

function getImageByService(service) {
  switch (service) {
    case "Simpl":
      return simpl;
    case "LazyPay":
      return lazyPay;
    case "OlaPostPaid":
      return ola;
    case "Kotak":
      return kotak;
    case "FlexiPay":
      return flexiPay;
    case "ZestMoney":
      return zestMoney;
    default:
      return null;
  }
}
